<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver ref="form">
        <Form
          :doctor-data.sync="formData"
          :cities-list="citiesList"
          :specialities-list="specialitiesList"
          :clinic-options.sync="clinicOptions"
          :doctor-options.sync="doctorOptions"
          :selected-options.sync="selectedOptions"
          @formSubmitClose="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = {
  id: null,
  slug: '',
  full_name: '',
  city_id: null,
  id_docdoc: null,
  status_docdoc: null,
  type: null,
  email: '',
  phone: '',
  photo: '',
  gender: null,
  degree: null,
  category: null,
  experience_start: null,
  education: '',
  courses: '',
  price: null,
  description: '',
  count_views: 0,
  is_show: 2,
  note: '',
  clinics: [],
  clinic_ids: [],
  main_speciality: null,
  other_specialities: null,
  redirect_id: null,
  redirect_doctor: null,
}

export default {
  name: 'DoctorsCreate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo: {
    title: 'Создание врача',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
      citiesList: [],
      specialitiesList: [],
      clinicOptions: [],
      doctorOptions: [],
      selectedOptions: [],
    }
  },
  mounted() {
    Promise.all([
      this.doLoadCities(),
      this.doLoadSpecialities(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave() {
      this.loading = true

      if (typeof this.formData.photo === 'string') { this.formData.photo = null }

      const formData = new FormDataConverter(this.formData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: 'doctors/create',
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          await this.$router.push({ name: 'doctors' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Врач создан, теперь он доступен для редактирования',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    async doLoadCities() {
      const response = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = response.data.data

      return response
    },
    async doLoadSpecialities() {
      const response = await axiosIns({
        method: 'GET',
        url: '/doctor_specialisations/options',
      })

      this.specialitiesList = response.data.data

      return response
    },
  },
}
</script>
